.createOrderButton {
    padding-top: 32px;

    .errorMessage {
        display: block;
        color: var(--color-error);
        text-align: center;
        font-weight: bold;
        font-family: Rubik, sans-serif;
        padding-bottom: 8px;
    }
}
