@import "../../../mixins";

.missingAddressBadge {
    @include badge;
    @include invalidBadge;

    padding: 8px 16px;
    text-align: center;

    img {
        width: 96px;
    }

    span {
        display: block;
        margin-bottom: 16px;
    }
}