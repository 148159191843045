@import "../../variables.scss";

.checkout {
    .content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        position: relative;
        left: 50%;
        transform: translateX(-50%);

        max-width: $maxWidthPage;
        padding: 36px;

        h1 {
            width: 100%;
        }

        > div {
            $margin: 24px;

            width: calc(50% - #{$margin});

            &:nth-child(2) {
                margin-right: $margin;
            }

            &:nth-child(3) {
                margin-left: $margin;
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        .content {
            > div {
                width: 100%;

                &:nth-child(2) {
                    margin-right: 0;
                    order: 3;
                }

                &:nth-child(3) {
                    margin-left: 0;
                    order: 2;
                }
            }
        }
    }
}
