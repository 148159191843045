@import "../../../mixins";
@import '../../../variables';

.searchAddress {
    .field {
        margin-bottom: 16px;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        .searchAddressOptions{
            max-height: 25vh;
            overflow: auto;
        }
    }
}

