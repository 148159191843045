.paymentInfo {
    .options {
        .option {
            display: flex;
            align-items: center;
            padding: 16px;
            border: 1px solid var(--color-border);
            margin-bottom: 16px;
            border-radius: var(--default-border-radius);

            &:hover {
                border-color: var(--color-accent-darker);

                > div span {
                    color: var(--color-accent-darker);

                    &:last-child {
                        color: var(--color-accent-darker);
                    }
                }
            }

            img {
                width: 36px;
                margin: 0 8px;

                cursor: pointer;
            }

            > div {
                margin-left: 16px;
                flex-grow: 1;

                cursor: pointer;

                span {
                    display: block;

                    &:first-child {
                        font-weight: bold;
                    }

                    &:last-child {
                        color: var(--color-font-secondary);
                    }
                }
            }

            .trashIcon {
                cursor: pointer;
                fill: var(--color-accent-darker);

                &:hover {
                    opacity: .8;
                }
            }

            &.selected {
                border-color: var(--color-accent);

                > div span {
                    color: var(--color-accent);

                    &:last-child {
                        color: var(--color-accent);
                    }
                }
            }

            &.disabled {
                opacity: .5;

                &:hover {
                    border-color: var(--color-border);

                    > div span {
                        color: var(--color-font-secondary);

                        &:last-child {
                            color: var(--color-font-secondary);
                        }
                    }
                }

                > div {
                    cursor: default;

                    span {
                        color: var(--color-font-secondary);

                        &:first-child {
                            font-weight: normal;
                        }
                    }
                }

                img {
                    cursor: default;
                }

                .trashIcon {
                    cursor: default;
                }
            }
        }
    }

    .newCardSection {
        display: flex;
        justify-content: space-between;

        margin-top: 48px;

        h3 {
            margin: 0 0 8px 0;
        }

        span {
            color: var(--color-font-secondary);
            margin-bottom: 16px;
            display: block;
        }

        img {
            width: 128px;
            margin: 0 8px;
        }
    }
}
