@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinLoading {
    $color: var(--color-accent);
    display: inline-block;
    position: relative;

    $wrap-size: 20px;
    $loading-size: $wrap-size * .8;
    $margin-size: $wrap-size * .1;

    width: $wrap-size;
    height: $wrap-size;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: $loading-size;
        height: $loading-size;
        margin: $margin-size;
        border: $margin-size solid $color;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $color transparent transparent transparent;
    }

    div:nth-child(1) {
        animation-delay: -0.45s;
    }

    div:nth-child(2) {
        animation-delay: -0.3s;
    }

    div:nth-child(3) {
        animation-delay: -0.15s;
    }
}
