@keyframes skeleton {
    0% {
        background-position: 0;
    }
    100% {
        background-position: 350px;
    }
}

.placeholder {
    border-radius: var(--default-border-radius);
    background: linear-gradient(to right, rgb(226, 214, 218) 0px, rgb(248, 242, 244) 120px, rgb(226, 214, 218) 240px) 0% 0% / 350px;
    animation: 0.9s linear 0s infinite normal none running skeleton;
}
