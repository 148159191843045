@mixin animation($properties...) {
    transition-property: $properties;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

@mixin basicButton {
    padding: 12px;
    border-radius: var(--default-border-radius);

    text-align: center;

    font-size: 1.3em;
    font-weight: bold;

    cursor: pointer;

    transition: background-color .3s;
}

@mixin dialog-background() {
    background-color: rgba(20, 20, 20, 0.8);
    @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
        backdrop-filter: blur(4px);
        background-color: rgba(20, 20, 20, 0.8);
    }

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

@mixin badge() {
    margin: 16px 0;
    border-radius: var(--default-border-radius);
    background: var(--color-background-light);
}

@mixin invalidBadge() {
    box-shadow: 0 0 6px var(--color-error);
    margin: 24px 0;

    .leadingIcon {
        filter: invert(16%) sepia(17%) saturate(7305%) hue-rotate(340deg) brightness(92%) contrast(116%)
    }

    .icon {
        fill: var(--color-error);
    }

    span {
        color: var(--color-error);
    }
}

@mixin roundedIcon($size) {
    height: $size;
    width: $size;
    border-radius: 50%;
    cursor: pointer;

    fill: var(--color-accent-darker);
    background: white;
    padding: 4px;

    &:hover {
        background: var(--color-background-light);
    }
}

@mixin labelTitle {
    display: block;
    font-size: .7rem;
    color: var(--color-font-secondary);
    line-height: .7rem;
}

@mixin baseCard($maxWidthPage, $mobileWidth) {
    $margin: 24px;

    max-width: $maxWidthPage;

    margin: $margin auto;
    box-shadow: 0 0 12px var(--color-box-shadow);
    background: var(--color-background-header);
    border-radius: var(--default-border-radius);
    padding: 24px;

    @media only screen and (max-width: $maxWidthPage - $margin * 2), (-webkit-min-device-pixel-ratio: 3) {
        margin: 32px $margin;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        box-shadow: none;
        background: var(--color-background);
        padding: 0 16px;
    }
}

@mixin elevation {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .3);
    background: var(--color-background-light);
}
