.button {
    padding: 8px 16px;
    border-radius: var(--default-border-radius);
    border: 1px solid transparent;

    background: var(--color-button);

    span {
        color: var(--color-font-button);
        font-weight: bold;
        text-align: center;
    }

    .suffix {
        margin-left: 4px;
    }


    cursor: pointer;

    &:hover {
        background: var(--color-button-hover);
    }

    &.disabled {
        cursor: pointer;

        background: var(--color-button-disabled);
        color: var(--color-font-button-disabled);
    }
}
