@import "../../mixins";
@import '../../variables';

$paddingTextArea: 16px;

.selectedOrderProduct {
    margin-top: 16px;
    min-height: 600px;
    display: flex;
    flex-direction: column;

    .content {

        flex-grow: 1;

        h4 {
            margin: 0;
        }

        .image img {
            width: 380px;
            height: 200px;
            object-fit: cover;
            border-radius: var(--default-border-radius);
        }

        .description {
            font-weight: normal;
            color: var(--color-font-secondary);
        }

        h5 {
            color: var(--color-font-faded);
        }

        .observation {
            textarea {
                width: calc(100% - #{$paddingTextArea * 2});
                height: 100px;
                margin-top: 8px;
                padding: $paddingTextArea;
                border-radius: var(--default-border-radius);
                border: 1px solid var(--color-border);
                font-family: Biotif, sans-serif;
                resize: none;
                color: var(--color-font-faded);
                outline: none;

                font-size: .8rem;

                &:focus {
                    border-color: var(--color-accent-lighter);
                }
            }
        }

        .options {
            h4 {
                margin-bottom: 8px;
            }

            .optionsList {
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;

                span {
                    padding: 8px 16px;
                    border: 2px solid var(--color-border);
                    border-radius: var(--default-border-radius);

                    color: var(--color-font-faded);
                    cursor: pointer;

                    &:hover {
                        background: var(--color-background-light);
                    }

                    &.selected {
                        cursor: default;
                        border-color: var(--color-accent-lighter);
                        color: var(--color-accent-lighter);

                        &:hover {
                            background: white;
                        }
                    }
                }
            }
        }
    }

    .footer {
        display: flex;
        justify-content: end;
        align-items: center;

        padding-top: 16px;

        .quantityController {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-right: 16px;
            border-radius: var(--default-border-radius);
            border: 1px solid var(--color-border);
            padding: 4px;

            span {
                width: 48px;
                display: block;
                text-align: center;
            }

            span:not(:nth-child(2)) {
                @include roundedIcon(18px);
                display: block;
                text-align: center;
                font-weight: bold;

                &.disabledQuantityController {
                    cursor: default;
                    background: white;
                    color: var(--color-font-label-field-disabled);

                    &:hover {
                        background: white;
                    }
                }
            }
        }
    }


    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        min-height: unset;

        .content {
            .image img {
                width: calc(100vw - #{$paddingDialog * 2});
            }
        }
    }
}
