@import "../../../mixins";

.discountCouponField {
    @include badge();

    padding: 16px;

    span {
        display: block;
        margin-bottom: 8px;
    }

    .errorLabel {
        color: var(--color-error);
        font-size: .8rem;
        display: block;
        text-align: right;
        padding-top: 8px;
        font-family: Rubik, sans-serif;
        font-weight: bold;
    }

    &.error {
        @include invalidBadge;
    }

    &.success {
        position: relative;

        .icon {
            fill: var(--color-success);
            position: absolute;
            $position: 8px;
            top: $position;
            right: $position;
        }

        span:last-child {
            text-align: center;
            color: var(--color-success);
            font-weight: bold;
        }
    }
}