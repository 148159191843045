@import '../../../variables';

.editAddress {
    margin: 24px 0;
    width: 600px;

    .form {
        display: flex;
        flex-wrap: wrap;

        .streetLine, .neighborHood, .number, .complement {
            margin-bottom: 16px;
        }

        .streetLine {
            width: 60%;
        }

        .neighborHood {
            width: 34%;
        }

        .number {
            width: 100%;
        }

        .complement {
            width: 100%;
        }
    }

    .actions {
        display: flex;
        justify-content: end;
        text-align: right;
    }


    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        width: 100%;
    }
}
