@import '../../../variables';

.statusRoadMap {
    $horizontalPadding: 32px;
    position: relative;

    display: flex;
    justify-content: space-between;

    padding: 48px $horizontalPadding;

    .step {
        position: relative;
        text-align: center;
        z-index: 1;

        span {
            margin-top: 56px;
            display: flex;
            justify-content: center;

            &:before {
                position: absolute;
                content: attr(data-step);
                $size: 48px;
                width: $size;
                height: $size;
                border-radius: 50%;
                background: var(--color-background-light);
                color: var(--color-accent-darker);
                transform: translateY(-64px);

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &.active span:before {
            background: var(--color-accent-darker);
            color: var(--color-background-light);
        }
    }

    .container {
        $height: 8px;

        position: absolute;
        width: calc(100% - #{$horizontalPadding * 2});
        height: $height;
        border-radius: var(--default-border-radius);
        background: var(--color-background-light);

        top: 60px;
        left: $horizontalPadding;

        .line {
            height: $height;
            border-radius: var(--default-border-radius);
            background: var(--color-accent-darker);
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        padding: 48px 0;

        .step span {
            font-size: .75rem;
        }
    }
}
