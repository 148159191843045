@import "../../../mixins";

.closedStoreBadge {
    @include invalidBadge;
    @include badge;

    margin: 16px 0;
    padding: 16px;

    span {
        display: block;

        &:nth-child(2) {
            padding-top: 8px;
            text-align: right;
            color: black;
        }
    }
}