@import "../../variables";
@import '../../mixins';

.products {
    max-width: $maxWidthPage;
    padding: 0 24px;
    margin: 0 auto;

    .category {
        text-align: left;
        padding: 8px 0;

        .productList {
            display: flex;
            flex-wrap: wrap;

            .product {
                width: 368px;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 0 32px 32px 0;

                border-radius: var(--default-border-radius);
                box-shadow: 1px 1px 12px var(--color-box-shadow);
                background: var(--color-background-header);

                .content {
                    display: flex;
                    justify-content: space-between;

                    padding: 16px 16px 0 16px;

                    .text {
                        padding-right: 16px;

                        h4 {
                            margin: 0 0 8px 0;
                        }

                        span {
                            font-weight: normal;
                            font-size: .75rem;
                            color: var(--color-font-secondary);

                            max-width: 150px;

                            display: block;

                            &:last-child, {
                                margin-top: 16px;
                                font-weight: bold;
                                color: var(--color-font);
                            }
                        }
                    }

                    img {
                        width: 120px;
                        height: 120px;

                        object-fit: cover;
                        border-radius: var(--default-border-radius);
                    }
                }

                .actions {
                    display: flex;
                    justify-content: space-between;

                    padding: 16px;

                    span {
                        display: flex;
                        align-items: center;
                    }

                    .button {
                        display: flex;
                        align-items: end;

                        .icon {
                            fill: white;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {

        .category .productList .product {
            width: 100%;
            margin: 0 0 24px 0;

            .content {
                .text span {
                    max-width: unset;
                }

                img {

                    width: 100px;
                    height: 100px;
                }
            }
        }
    }
}
