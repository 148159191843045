@import "../../../mixins";

.productsCart {
    $box-shadow: 1px 1px 8px var(--color-box-shadow);

    .product {
        box-shadow: $box-shadow;
        margin: 24px 0;
        border-radius: var(--default-border-radius);

        > * {
            padding: 8px;
        }

        .content {
            display: flex;
            position: relative;
            align-items: start;

            img {
                $size: 48px;
                width: $size;
                height: $size;
                object-fit: cover;
                border-radius: var(--default-border-radius);
            }

            .info {
                margin-left: 8px;
                flex-grow: 1;

                h4, h6, span {
                    margin: 0;
                }

                h6 {
                    margin: 8px 0;
                    padding: 4px 8px;
                    display: inline-block;
                    border-radius: var(--default-border-radius);
                    border: 1px solid var(--color-border);
                    color: var(--color-font-faded);
                }

                span {
                    display: block;
                    padding-right: 16px;
                    font-size: .8rem;
                    font-weight: bold;
                    font-family: Rubik, sans-serif;
                    color: var(--color-accent-darker);
                }
            }

            .editIcon {
                $size: 18px;
                @include roundedIcon($size);

                $position: 8px; // $size * -.5;
                position: absolute;
                top: $position;
                right: $position;

                box-shadow: $box-shadow;

                &:hover {
                    box-shadow: 1px 1px 8px #00000055;
                }
            }
        }

        .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid var(--color-divider-lighter);

            > span {
                color: var(--color-font-faded);

                font-size: .8rem;
            }

            .quantityController {
                display: flex;
                align-items: center;

                span {
                    margin-left: 12px;

                    &:not(:nth-child(2)) {
                        $size: 18px;
                        font-weight: bold;

                        @include roundedIcon($size);
                        border: 1px solid var(--color-border);
                        display: block;
                        text-align: center;

                        .trashIcon {
                            height: $size;
                            width: $size;
                        }
                    }
                }
            }
        }
    }
}
