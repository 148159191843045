.productsCheckout{
    margin: 24px 0;

    .product{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: 8px 0;

        span:nth-child(3){
            margin-top: 4px;
            width: 70%;
            font-size: .7rem;
            color: var(--color-accent-darker)
        }
        .price {
            display: flex;
            align-items: center;
        }

        .icon {
            align-self: center;
        }
    }
}
