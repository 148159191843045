@import '../../variables';
@import '../../mixins';

.signInOrUp {
    position: relative;

    background: var(--color-background-light);
    height: 100vh;
    overflow: hidden;

    &::before {
        position: absolute;
        content: ' ';
        border-radius: 50%;
        $size: 150vw;
        width: $size;
        height: $size;
        top: -27vw;
        left: -70vw;
        background: var(--color-background-login);
    }



    .form {
        position: absolute;
        right: 5vw;
        top: 40vh;
        width: 320px;

        padding: 32px;

        text-align: center;

        background: var(--color-background-header);
        box-shadow: 0 0 12px var(--color-box-shadow);

        border-radius: var(--default-border-radius);

        &.error {
            box-shadow: 0 0 12px var(--color-error);
        }

        .header {
            position: relative;
            margin-bottom: 32px;

            h3 {
                margin-top: 0;
            }

            .backIcon {
                position: absolute;
                left: 0;
            }
        }

        .actionButton {
            margin-top: 24px;
        }

        .errorMessage {
            color: var(--color-error);
        }

        .createAccountButton {
            margin-top: 48px;
        }

        .field {
            margin-bottom: 16px;
        }
    }

    .logo {
        $position: 32px;
        position: absolute;
        top: $position;
        left: $position;

        img {
            width: 120px;
        }
    }

    .ownerImage {
        position: absolute;
        top: 30vh;
        left: 0;

        width: 50vh;

        filter: invert(.8);
    }

    .entrecoteImage {
        position: absolute;
        width: 200px;
        filter: invert(.8);
        top: 10vh;
        left: 40vw;
    }

    .cuttingBoardImage {
        position: absolute;
        width: 170px;
        filter: invert(.8);
        top: 20vh;
        left: 3vw;
        transform: rotate(32deg);
    }

    .cleaverImage {
        position: absolute;
        width: 180px;
        filter: invert(.8);
        left: 50vw;
        bottom: 5vh;
        transform: rotate(42deg);
    }

    .errorLabel {
        color: var(--color-error);
        font-size: .8rem;
        display: block;
        text-align: center;
        padding-top: 8px;
        font-family: Rubik, sans-serif;
        font-weight: bold;
    }

    @media only screen and (max-width: $tabletWidth) {
        &::before {
            $size: 180vw;
            width: $size;
            height: $size;
        }

        .ownerImage {
            top: unset;
            bottom: 0;
            width: 42vh;
        }

        .cleaverImage {
            right: 5vw;
            left: unset;
            bottom: unset;
            top: 30vw;
        }

        .form {
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        &::before {
            $size: 105vh;
            width: $size;
            height: $size;
            top: -2.5vh;
            left: -50vh;
        }

        .form {
            position: relative;
            margin: 0 auto;

            right: unset;
            top: unset;

            width: 75vw;
        }

        .cuttingBoardImage {
            display: none;
        }

        .entrecoteImage {
            bottom: 3vh;
            top: unset;
            left: 25vw;
            width: 120px;
            transform: rotate(40deg);
        }

        .cleaverImage {
            display: none;
        }

        .ownerImage {
            top: 15vh;
            width: 80vw;

            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}


