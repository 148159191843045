@import '../../variables.scss';

.chooseDeliveryMethod {
    width: 600px;

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        width: 100%;
        &.fixedHeight {
            height: 70vh;
        }
    }
}


.backButton {
    cursor: pointer;
    fill: var(--color-accent);

    &:hover {
        fill: var(--color-accent-darker);
    }
}
