@import '../../../variables';

.editPersonalData {
    margin: 16px 0;

    display: flex;
    justify-content: space-between;

    > div {
        display: flex;
        align-items: center;

        span {
            margin-right: 8px;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        flex-direction: column;

        > div {
            margin-bottom: 16px;

            span {
                display: block;
            }
        }
    }
}
