.orders {
    table {
        width: 100%;

        tr td {
            cursor: pointer;
            text-align: center;
        }
    }
}
