@import '../../variables';

.addCreditCard {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;

    width: 600px;

    > * {
        width: 50%;

        &:first-child {
            width: calc(50% - 24px);
            margin: 0 24px 0 0;
        }
    }

    .field {
        margin-bottom: 16px;
    }

    .actions {
        margin-top: 8px;
        width: 100%;
        display: flex;
        justify-content: end;
    }

    h5 {
        margin: 0 0 24px 0;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        flex-direction: column;
        align-items: center;
        width: unset;

        > * {
            width: 100%;

            &:first-child {
                width: 100%;
                margin: 0 0 24px 0;
            }
        }
    }
}

