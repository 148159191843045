@import "../../../mixins";

.minimumPurchaseBadge {
    @include badge;
    padding: 0 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > * {
        display: flex;
        align-items: center;

        font-size: .8rem;

        > :last-child {
            padding-left: 8px;
        }
    }

    .leadingIcon {
        transform: rotate(90deg);
        filter: invert(1);
    }

    &.invalid {
        @include invalidBadge;
    }

    &.valid {
        .icon {
            fill: var(--color-success);
        }
    }
}