
.addressOption {
    margin-bottom: 16px;
    padding: 8px;

    background: var(--color-background-lighter);
    border-radius: var(--default-border-radius);
    border: 1px transparent solid;

    display: flex;
    align-items: center;

    .icon {
        padding: 8px;
        max-width: 48px;
    }

    .text {
        flex-grow: 1;

        span {
            display: block;
            font-size: .8rem;

            &:nth-child(2) {
                font-family: Rubik, sans-serif;
            }
        }
    }

    &.isClickable {
        cursor: pointer;

        :hover {
            border-color: var(--color-border-field);
            background: var(--color-background);
        }
    }

    &.isError {
        box-shadow: 0 0 4px red;

        color: var(--color-error);

        .text {
            span:nth-child(2) {
                font-weight: bold;
            }
        }

        .icon {
            fill: var(--color-error)
        }
    }

    &.selected {
        border: var(--color-accent) solid 1px;

        color: var(--color-accent-darker);

        .icon {
            fill: var(--color-accent-darker);
        }
    }

    &.isRemovable &.isClickable {
        cursor: default;

        .text {
            cursor: pointer;
        }
    }

    &.isLoading {
        cursor: default;

        .text {
            cursor: default;
        }

        .icon {
            cursor: default
        }

        &:hover {
            border-color: transparent;
            background: var(--color-background-lighter);
        }
    }
}
