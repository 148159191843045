@import '../../../variables';
@import '../../../mixins';

.appBar {
    background: var(--color-background-header);
    border-bottom: 1px solid var(--color-divider);

    .content {
        max-width: $maxWidthPage;
        padding: 16px 24px;

        margin: 0 auto;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            cursor: pointer;

            img {
                width: 80px;
            }
        }
    }
}
