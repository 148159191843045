.tabs {
    display: flex;
    align-items: center;

    span {
        margin-right: 32px;
        padding: 16px 0;
        cursor: pointer;
        display: block;

        &:hover {
            color: var(--color-accent-lighter);
        }

        &.active {
            cursor: default;
            color: var(--color-accent);
            font-weight: bold;
            border-bottom: 2px solid var(--color-accent);
        }
    }
}