.field {
    position: relative;
    border: 1px var(--color-border-field) solid;
    border-radius: var(--field-border-radius);

    display: flex;
    align-items: center;

    background: var(--color-background-field);

    .prefix {
        padding-left: 8px;
        transform: translateY(4px);
    }

    .suffix {
        padding-right: 16px;
        transform: translateY(4px);
    }

    input {
        border: none;
        width: 100%;

        background: var(--color-background-field);

        margin: 0;
        padding: 4px 8px;

        font-size: 0.9rem;
        color: var(--color-text-small-subtitle);
        border-radius: var(--field-border-radius);

        height: 2rem;

        &:focus {
            border-bottom: none !important;
            box-shadow: none !important;
            -webkit-box-shadow: none !important;
            outline: none !important;
        }
    }

    &:focus-within {
        border-color: var(--color-accent);
    }
}
