@import '../../mixins';
@import '../../variables';

.profile {
    @include baseCard($maxWidthPage, $mobileWidth);

    h2 {
        margin: 0;
    }

    .actions {
        display: flex;
        justify-content: end;

        margin-top: 16px;
    }


    .version {
        position: absolute;
        right: 16px;
        bottom: 16px;
        color: var(--color-font-hidden);
    }


    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        .actions {
            display: block;
        }

        .version {
            position: unset;
            text-align: right;
            display: block;
            margin-top: 16px;
        }

    }
}


