.actionCart {
    $size: 180px;
    $padding: 16px;
    height: $size - ($padding * 2);
    box-shadow: -8px -8px 16px var(--color-box-shadow);

    padding: $padding;

    display: flex;
    flex-direction: column;

    .subTotals {
        flex-grow: 1;

        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0;

            span:first-child {
                font-size: .8rem;
                color: var(--color-font-secondary);
            }

            span:last-child {
                font-size: .9rem;
                font-family: Rubik, sans-serif;
                font-weight: bold;
            }
        }
    }

    .action {
        display: flex;
        align-items: center;
        justify-content: space-between;

        border-top: 1px solid var(--color-divider-lighter);
        padding-top: 8px;

        .total span {
            display: block;

            &:first-child {
                font-size: .7rem;
                color: var(--color-font-secondary);
            }
        }
    }
}