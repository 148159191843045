@import '../../../mixins';

.label {
    display: flex;
    justify-content: space-between;
    align-items: end;

    margin: 8px 0;

    .content span {
        display: block;

        &:first-child {
            @include labelTitle;
        }
    }

    .editIcon {
        margin-left: 16px;
    }
}
