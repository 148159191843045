.profile {
    display: flex;
    align-items: center;

    cursor: pointer;

    .accountIcon {
        fill: var(--color-accent);
        margin-right: 8px;
    }

    span {
        color: var(--color-accent);
    }

    &:hover {
        span {
            color: var(--color-accent-darker);
        }

        .accountIcon {
            fill: var(--color-accent-darker);
        }
    }
}
