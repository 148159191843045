@import '../../../variables';

.orderProduct {

    background: var(--color-background);

    width: 200px;

    padding: 16px;
    margin-right: 32px;

    border-radius: var(--default-border-radius);

    .image img {

        border-radius: var(--default-border-radius);

        width: 100%;
        height: 120px;
        object-fit: cover;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        box-shadow: 0 0 12px var(--color-box-shadow);

        width: calc(100% - 32px);
        margin: 0 0 32px 0;
    }
}
