@import "../../../mixins";

.fieldWithLabel {
    margin: 8px;
    position: relative;
    border: 1px var(--color-border-field) solid;
    border-radius: var(--field-border-radius);

    display: flex;
    align-items: center;
    background: var(--color-background-field);

    .input {
        border: none;
        width: 100%;

        background: var(--color-background-field);

        margin: 0;
        padding: 4px 16px;

        font-size: 0.9rem;
        //color: var(--color-text-small-subtitle);
        border-radius: var(--field-border-radius);

        height: 2rem;

        &:focus {
            border-bottom: none !important;
            box-shadow: none !important;
            -webkit-box-shadow: none !important;
            outline: none !important;
        }

        &::placeholder {
            color: transparent;
            @include animation(color);
        }
    }

    label {
        position: absolute;
        top: 50%;
        left: 8px;

        padding: 0 8px;

        transform: translateY(-50%);

        line-height: 1rem;
        font-size: 1.1rem;

        cursor: text;

        color: var(--color-font-field-placeholder);
        @include animation(font-size, top, left, border);
    }

    &:focus-within, &.hasValue {
        label {
            top: 0;
            left: 16px;
            transform: translateY(-55%);
            background: var(--color-background-field);
            color: var(--color-font-field-label);

            border-top-left-radius: var(--default-border-radius);
            border-top-right-radius: var(--default-border-radius);

            font-size: .9em;
        }

        .input::placeholder {
            color: var(--color-font-field-placeholder);
        }
    }

    &:focus-within {
        border: 1px var(--color-accent-darker) solid;

        label {
            color: var(--color-accent-lighter);
        }
    }

    &.disabled {
        border-color: var(--color-background-field-disabled);
        border: none;

        .input {
            background: var(--color-background-field-disabled);
            color: var(--color-font-field-disabled);
        }

        label {
            background: var(--color-background-field-disabled);
            color: var(--color-font-field-disabled);
        }
    }
}