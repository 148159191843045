@import '../../variables';
@import '../../mixins';

.order {
    @include baseCard($maxWidthPage, $mobileWidth);

    display: flex;
    flex-direction: column;

    &.pending {
        .statusRoadMap {
            display: none;
        }

        .totalSection {
            display: none;
        }
    }

    .copyButton {
        margin-left: 8px;
    }

    .qrCode {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h1 {
        margin: 0;
    }

    .basicInfo {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 16px;

        > * {
            margin: 0 24px 16px 0;
        }
    }

    .products {
        display: flex;
        flex-wrap: wrap;
    }

    .totalSection {
        align-self: end;

        margin-top: 16px;

        h2 {
            margin: 0;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        padding: 0;
    }
}
