@import "../../../mixins";
@import '../../../variables';

.dialog {
    @include dialog-background;

    .content {
        background-color: var(--color-background-dialog);
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: $paddingDialog;
        border-radius: var(--default-border-radius);

        width: fit-content;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                display: flex;

                .leading {
                    padding-right: 8px;
                }


                h3 {
                    padding: 0;
                    margin: 0;
                }

            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        .content {
            position: absolute;
            top: unset;
            left: 0;
            bottom: 0;
            transform: none;
            width: calc(100vw - #{$paddingDialog * 2});
        }
    }
}
