@import 'variables';
@import 'mixins';

:root {
    --color-background: #f5f5f5;
    --color-background-header: #ffffff;
    --color-background-dialog: #ffffff;
    --color-background-light: rgb(234, 234, 234);
    --color-background-lighter: rgb(248, 248, 248);
    --color-background-login: #8d601e77;
    --color-box-shadow: #00000033;

    --color-text-level-lighter: #b68f38;
    --color-accent-lighter: #b68f38;
    --color-accent: #8d601e;
    --color-accent-darker: #684b16;
    --color-accent-transparent: #b68f3811;

    --color-font: black;
    --color-font-faded: #444444;
    --color-font-secondary: #707070;
    --color-font-on-accents: #ffffff;
    --color-font-hidden: #c5c5c5;
    --color-font-field-placeholder: #555555;
    --color-font-field-label: #333333;

    --color-button: #8d601e;
    --color-button-hover: #684b16;
    --color-font-button: #ffffff;
    --color-button-disabled: #c5c5c5;
    --color-font-button-disabled: #818181;

    --color-selected: #b68f38;
    --color-switch-background: #593e10;
    --color-switch-off: rgba(194, 194, 194, 0.19);
    --color-switch-background-off: rgb(193 193 193);

    --color-background-field: #fdfaf3;
    --color-background-field-disabled: #c5c5c5;
    --color-font-field-disabled: #696969;
    --color-font-label-field-disabled: #696969;

    --color-divider: #30210955;
    --color-divider-light: #30210933;
    --color-divider-lighter: #30210922;
    --color-border: #30210955;
    --color-border-field: #30210944;

    --color-error: #6c0404;
    --color-success: #0a770a;

    --default-border-radius: 8px;
    --field-border-radius: 16px;
}

@font-face {
    font-family: Biotif;
    font-weight: normal;
    src: local("Biotif"), url("./assets/fonts/Biotif-Medium.ttf") format('truetype');
}

@font-face {
    font-family: Biotif;
    font-weight: 100;
    src: local('Biotif-Light'), url("./assets/fonts/Biotif-Black.ttf") format('truetype');
}

@font-face {
    font-family: Biotif;
    font-weight: bold;
    src: local('Biotif-Black'), url("./assets/fonts/Biotif-Bold.ttf") format('truetype');
}

@font-face {
    font-family: Rubik;
    font-weight: 100;
    src: local('Rubik-Light'), url("./assets/fonts/Rubik-Light.ttf") format('truetype');
}

body, html {
    margin: 0;

    background: var(--color-background);
}

a, p, pre, span, h1, h2, h3, h4, h5, div {
    font-family: Biotif, sans-serif;
    color: var(--color-font);
}


.clickable-icon {
    cursor: pointer;
    fill: var(--color-accent);

    &:hover {
        fill: var(--color-accent-darker);
    }
}


table {
    border-collapse: collapse;

    tbody tr:nth-child(odd) td {
        background: var(--color-background-light);
    }

    thead th {
        padding: 16px 0;
    }

    td {
        font-size: 14px;
        padding: 8px 16px;

        font-weight: 500;
        text-align: center;
    }
}

$borderTable: var(--radius-default);

tr:first-child th:first-child {
    border-top-left-radius: $borderTable;
}

tr:first-child th:last-child {
    border-top-right-radius: $borderTable;
}

tr:last-child td:first-child {
    border-bottom-left-radius: $borderTable;
}

tr:last-child td:last-child {
    border-bottom-right-radius: $borderTable;
}


@media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
    table {
        display: inline;

        thead {
            display: none;
        }

        tbody {
            display: flex;
            flex-direction: column;

            tr {
                display: flex;
                flex-direction: column;

                width: 100%;
                position: relative;
                margin: 16px 0;
                padding: 8px 0;

                border-radius: var(--default-border-radius);
                background: var(--color-background-light);

                &:nth-child(odd) td {
                    background: var(--color-background-light);
                }

                td {
                    display: inline;
                    text-align: left;

                    &:before {
                        content: attr(data-title);
                        @include labelTitle;
                    }
                }
            }
        }
    }
}
