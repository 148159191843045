@import "../../mixins";
@import '../../variables';


.cartBackground {
    @include dialog-background;

    .cart {
        $paddingCart: 16px;

        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        width: 440px;

        display: flex;
        flex-direction: column;

        background: var(--color-background-dialog);

        @include animation(transform);
        transform: translateX(100%);

        &.opening {
            transform: none;
        }

        .header {
            display: flex;
            align-items: center;
            padding: $paddingCart;

            h5 {
                margin: 0;
                flex-grow: 1;
                padding-left: 16px;
                color: var(--color-accent-darker);
            }
        }

        .cartContent {
            > * {
                margin: $paddingCart;
            }

            max-height: calc(100vh - #{$paddingCart * 2} - 24px - 180px);
            overflow-y: auto;

            flex-grow: 1;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        .cart {
            width: 100%;
        }
    }

}
