@import '../../../variables';

.cartSummary {
    box-shadow: 0 0 12px var(--color-box-shadow);
    padding: 24px;
    border-radius: var(--default-border-radius);


    .summaryTitle {
        display: flex;
        justify-content: space-between;

        h2 {
            margin: 0;
        }

        span {
            color: var(--color-accent);
            align-self: center;
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .addMoreItems {
        display: flex;
        justify-content: center;

        span {
            color: var(--color-accent);
            align-self: center;
            cursor: pointer;
            text-decoration: underline;
        }
    }



    .priceSummary {
        padding-top: 16px;
        margin-top: 32px;
        border-top: 1px solid var(--color-border);

        div {
            display: flex;
            justify-content: space-between;

            margin-top: 4px;

            span {
                color: var(--color-font-secondary);
                font-size: .9rem;
            }

            &:last-child {
                margin-top: 16px;

                span {
                    color: var(--color-font);
                    font-weight: bold;
                    font-size: 1.1rem;
                }
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        box-shadow: none;
        padding: 0 0 24px 0;

        h2 {
            display: none;
        }
    }
}
