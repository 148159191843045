@import "../../../mixins";

.outline-button {
    @include basicButton;

    border: 1px solid var(--color-border);

    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--color-accent-darker);

    font-size: 1rem;

    &:hover {
        background-color: var(--color-background-light);
    }
}
