@import '../../../variables';

.deliveryCheckout {

    .deliveryInfo {
        padding-top: 24px;
        display: flex;

        > * {
            margin-right: 16px;
        }

        .texts {
            flex-grow: 1;

            span {
                display: block;

                &:first-child {
                    font-weight: bold;
                }

                &:last-child {
                    color: var(--color-font-secondary);
                    font-family: Rubik, sans-serif;
                }
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        .deliveryInfo {
            flex-wrap: wrap;

            > * {
                margin-bottom: 16px;
            }
        }
    }
}
