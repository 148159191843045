
.chooseCustomAddress {
    margin: 16px 0;
    padding: 16px;
    background: var(--color-background-light);

    border-radius: var(--default-border-radius);


}
