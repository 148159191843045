@import '../../../variables';
@import '../../../mixins';

.addressBar {
    background: var(--color-background-header);
    box-shadow: rgba(35, 31, 32, 0.1) 0 2px 4px;

    .content {
        display: flex;
        justify-content: space-between;

        max-width: $maxWidthPage;
        padding: 16px 24px;
        margin: 0 auto;

        .chosenAddress {
            display: flex;
            align-items: center;
            color: var(--color-accent);

            cursor: pointer;

            &:hover {
                color: var(--color-accent-darker);

                .icon {
                    fill: var(--color-accent-darker);
                }
            }

            .icon {
                fill: var(--color-accent);
                padding-right: 8px;
            }

            span:nth-child(2) {
                padding-right: 4px;
            }

            span:nth-child(3) {
                font-family: Rubik, sans-serif;
                font-weight: 100;
                font-size: 0.9rem;
                padding-bottom: 1px;
            }
        }

        .actions {
            display: flex;

            .chooseDeliveryMethod {
                border: 1px solid var(--color-border);
                border-radius: var(--default-border-radius);
                margin: 0 16px;

                padding: 16px;

                cursor: pointer;

                span {
                    padding: 8px 16px;
                    border-radius: var(--default-border-radius);
                    border: 1px solid transparent;

                    &:first-child {
                        margin-right: 8px;
                    }

                    &.active {
                        background: var(--color-accent);
                        color: var(--color-font-on-accents);
                        font-family: Rubik, sans-serif;
                    }

                    &:hover {
                        border: 1px solid var(--color-accent-lighter);
                    }
                }
            }

            .totalInCard {
                cursor: pointer;
                border-left: 1px solid var(--color-divider);
                padding-left: 16px;
                display: flex;
                align-items: center;

                font-family: Rubik, sans-serif;

                .icon {
                    padding-right: 8px;
                    fill: var(--color-accent);
                }

                span {
                    font-size: .9rem;
                }
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        position: sticky;
        top: 0;

        .content {
            justify-content: center;

            .chosenAddress {
                span {
                    color: var(--color-accent);
                    font-weight: bold;

                    margin-right: 8px;

                    text-align: center;
                }

                .icon {
                    display: none;
                }

                span:nth-child(2) {
                    display: none;
                }
            }

            .actions {
                .chooseDeliveryMethod {
                    display: none;
                }
            }
        }
    }
}
