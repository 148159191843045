.whatsapp {
    position: fixed;
    bottom: 20px;
    right: 20px;

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    img {
        width: 40px;
        height: 40px;
    }

    &:hover {
        background-color: #128c7e;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
}